<div class="p-2 " >
  <div class="d-flex">
    <mat-icon>expand_more</mat-icon>
    <span><strong>{{ user.email }}</strong></span>
  </div>

  <div class="d-flex ms-4 m-3">
    <mat-icon>inbox</mat-icon>
    <span class="ms-2">{{ 'side_nav.inbox' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon>edit_note</mat-icon>
    <span class="ms-2">{{ 'side_nav.drafts' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-send.png">
    <span class="ms-2">{{ 'side_nav.sent_items' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon style="margin-left: -24px">navigate_next</mat-icon>
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-trash.svg">
    <span class="ms-2">{{ 'side_nav.deleted_items' | translate }}</span>
    <span style="color: #469EF6;margin-left: auto">12</span>
  </div>
  <div class="d-flex ms-4 mt-1 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-spam.png">
    <span class="ms-2">{{ 'side_nav.junk_email' | translate }}</span>
    <span style="margin-left: auto">20</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-box.svg">
    <span class="ms-2">{{ 'side_nav.archive' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-note.png">
    <span class="ms-2">{{ 'side_nav.notes' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-folder.svg">
    <span class="ms-2">{{ 'side_nav.conversation_history' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-folder.svg">
    <span class="ms-2">{{ 'side_nav.quick_setup_settings' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon style="margin-left: -24px">navigate_next</mat-icon>
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-folder.svg">
    <span class="ms-2">{{ 'side_nav.sync_issues' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-search-folder.png">
    <span class="ms-2">{{ 'side_nav.search_folders' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon style="margin-left: -24px">navigate_next</mat-icon>
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/link-square.svg">
    <span class="ms-2">{{ 'side_nav.shared_with_me' | translate }}</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon style="margin-left: -24px">navigate_next</mat-icon>
    <mat-icon>group</mat-icon>
    <span class="ms-2">{{ 'side_nav.groups' | translate }}</span>
  </div>

  <div class="d-flex ms-4" style="color: #469EF6">
    <mat-icon>person_add_alt</mat-icon>
    <span class="ms-2">{{ 'side_nav.app_account' | translate }}</span>
  </div>
</div>
