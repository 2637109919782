import {Injectable} from "@angular/core";
import {HttpServiceFacade} from "../http.service.facade";
import {SettingFilterInput, SettingGroup, SettingType, SettingValueList} from "../model/setting.model";
import {Observable} from "rxjs/internal/Observable";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ValidatorsService} from "./validator.service";
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class SettingService {


    constructor(
        private backendService: HttpServiceFacade,
        private validatorsService: ValidatorsService
    ) {
    }

    saveSettings(input: SettingValueList): Observable<SettingGroup[]> {
        return this.backendService.put<SettingGroup[]>('/api/settings/', input);
    }

    toFormGroup(settingGroup: SettingGroup) {
        const group: any = {};
        settingGroup.settingDTOList.forEach(setting => {
            group[setting.settingKey] = new FormControl(setting.value || '');
            if (setting.typeName == SettingType.INTEGER) {
                group[setting.settingKey].setValidators(
                    Validators.compose([this.validatorsService.isNumber(),
                        this.validatorsService.min(setting.minValue), this.validatorsService.max(setting.maxValue)]));
            } else if (setting.typeName == SettingType.TEXT) {
                const validators: any[] = [];
                validators.push((setting.minValue == 1) ? Validators.required : Validators.minLength(setting.minValue));
                validators.push(Validators.maxLength(setting.maxValue));

                group[setting.settingKey].setValidators(Validators.compose(validators));
            }
        });

        return new FormGroup(group);
    }

    listSettings(input: SettingFilterInput): Observable<SettingGroup[]> {
        return this.backendService.post<SettingGroup[]>('/api/settings/', input);
    }

    getSettingValue(input: SettingFilterInput): Observable<string> {
        return this.backendService.postWithOptions<string>(
          `/api/settings/${input.settingKey}`,
          input,
          { responseType: 'text', observe: 'body' }
        ).pipe(
          map(response => response as unknown as string)
        );
      }

}
