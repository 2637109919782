<div class="d-flex justify-content-center align-items-center me-3" style="width: auto">
    <a class="icon-link btn-icon">
      <app-theme-toggle></app-theme-toggle>
    </a>
    
    <a (click)="navigateToCustomer()"
       [class.disabled]="userDataStoreService.isEXEMode()"
       class="icon-link btn-icon mt-2">
      <mat-icon [matTooltip]="'common.settings' | translate">settings_outline</mat-icon>
    </a>
    
    <app-language-selector></app-language-selector>
    
    <a *ngIf="userService.getAllowLogout()"
       class="icon-link btn-icon mt-2"
       (click)="startOver()">
      <mat-icon [matTooltip]="'common.log_out' | translate">logout</mat-icon>
    </a>
</div>  