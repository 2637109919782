<div [formGroup]="countryGroup">
    <div class="row row-cols-2">
        <mat-form-field appearance="outline" floatLabel="auto">
            <mat-label>{{'customer.country' | translate}}</mat-label>
            <mat-icon class="me-1"  matPrefix>flag</mat-icon>
            <mat-select (selectionChange)="handleCountryChange()" [compareWith]="compareStrings"
                        formControlName="country">
                <mat-option *ngFor="let option of suggestedCountries; let i=index" [value]="option.name">
                    {{ option.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="!getFormControl('country')?.valid">
                {{'validation.country_required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="auto">
            <mat-label>{{'customer.state' | translate}}</mat-label>
            <mat-icon class="me-1"  matPrefix>flag</mat-icon>
            <mat-select (selectionChange)="handleStateChange()" [compareWith]="compareStrings" formControlName="state">
                <mat-option *ngFor="let option of suggestedStates; let i=index" [value]="option.name">
                    {{ option.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="!getFormControl('state')?.valid">
                {{'validation.state_required' | translate}}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row row-cols-2">
        <mat-form-field appearance="outline" floatLabel="auto">
            <mat-label>{{'customer.city' | translate}}</mat-label>
            <mat-icon class="me-1"  matPrefix>flag</mat-icon>
            <input [formControl]="citySearchControl" [matAutocomplete]="auto" matInput placeholder="{{ 'customer.city' | translate }}"
                   type="text">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngIf="loadingCountries" class="p-3 row" disabled>
                    <span class="col-12 d-flex flex-row justify-content-center">
                        <mat-spinner color="primary" diameter="20" strokeWidth="1"></mat-spinner>
                    </span>
                </mat-option>
                <mat-option *ngFor="let option of filteredCities | async" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="!getFormControl('citySearchControl')?.valid">
                {{'validation.city_required' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="auto">
            <mat-label>{{'customer.postal_code' | translate}}</mat-label>
            <mat-icon class="me-1"  matPrefix>local_post_office</mat-icon>
            <input formControlName="postalCode" matInput placeholder="{{ 'customer.postal_code' | translate }}">

            <mat-error *ngIf="!getFormControl('postalCode')?.valid">
                {{'validation.postal_code_required' | translate}}
            </mat-error>
        </mat-form-field>
    </div>
</div>
