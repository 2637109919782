import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { UserDataStoreService } from '../shared/service/user-data-store.service';

@Component({
  selector: 'app-settings-icons',
  templateUrl: './settings-icons.component.html',
  styleUrls: ['./settings-icons.component.css']
})
export class SettingsIconsComponent {
  constructor(
    public userService: UserService,
    public userDataStoreService: UserDataStoreService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  navigateToCustomer(): void {
    this.userService.handleSimulation(false);
    if (this.userDataStoreService.isAdmin() || this.userDataStoreService.isSuperAdmin()) {
      this.router.navigate([`/admin`], { relativeTo: this.route });
    } else {
      const customer = this.userDataStoreService.getUser().customerDTO;
      this.router.navigate([`/customers/`, customer!.id, customer!.name], { relativeTo: this.route });
    }
  }

  startOver(): void {
    this.userService.logout();
  }
}