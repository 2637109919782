import { Component, OnInit } from '@angular/core';
import { SettingService } from '../shared/service/setting.service';
import { LocalizeService } from '../services/localize.service';
import { AlertPopupService } from '../services/alert.popup.service';
import { UserDataStoreService } from '../shared/service/user-data-store.service';
import { SettingFilterInput, SettingGroup, SettingPageEnum, SettingsKeys, SettingValueList, SettingItemDisplayValue } from 'src/app/shared/model/setting.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit {
  availableLanguages: SettingItemDisplayValue[] = [];
  currentLanguage: string = '';
  loading = false;

  constructor(
    private settingService: SettingService,
    private alertPopupService: AlertPopupService,
    private localizeService: LocalizeService,
    private userDataStoreService: UserDataStoreService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadSettings();
  }

  loadSettings() {
    const input: SettingFilterInput = {
      page: SettingPageEnum.USER,
      userId: this.userDataStoreService.getUser().userID,
      settingKey: SettingsKeys.LANGUAGE
    };

    this.settingService.listSettings(input).subscribe({
      next: (data: SettingGroup[]) => {
        if (Array.isArray(data)) {
          data.forEach(group => {
            if (group.settingDTOList && Array.isArray(group.settingDTOList)) {
              group.settingDTOList.forEach(settingDTO => {
                if (settingDTO.displayValues && Array.isArray(settingDTO.displayValues)) {
                  this.availableLanguages = settingDTO.displayValues;
                }
              });
            }
          });
        }

        const currentLangKey = this.localizeService.translate.currentLang || 'en';
        const languageMapping = this.availableLanguages.find(item => item.value === currentLangKey);
        this.currentLanguage = languageMapping ? languageMapping.displayKey : 'English';
      },
      error: () => {
        this.alertPopupService.error(this.translate.instant('dialog.language_load_failed'));
      }
    });
  }

  setLanguage(settingItem: SettingItemDisplayValue) {
    if (this.loading) return;
    this.loading = true;

    const input: SettingValueList = {
      userId: this.userDataStoreService.getUser().userID,
      page: SettingPageEnum.USER,
      settingValueList: [{
        settingKey: SettingsKeys.LANGUAGE,
        value: settingItem.value
      }]
    };

    this.settingService.saveSettings(input).subscribe({
      next: () => {
        this.localizeService.translate.use(settingItem.value).subscribe({
          next: () => {
            this.currentLanguage = settingItem.displayKey;
            this.alertPopupService.success(this.translate.instant('dialog.language_updated'));
            this.loading = false;
          },
          error: () => {
            this.alertPopupService.error(this.translate.instant('dialog.language_saved_translation_failed'));
            this.loading = false;
          }
        });
      },
      error: () => {
        this.alertPopupService.error(this.translate.instant('dialog.language_save_failed'));
        this.loading = false;
      }
    });
  }

}