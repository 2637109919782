<mat-menu #languageMenu="matMenu">
    <button *ngFor="let lang of availableLanguages" mat-menu-item 
            (click)="setLanguage(lang)"
            [disabled]="loading"
            [class.selected-language]="lang.displayKey === currentLanguage">
            <mat-progress-spinner *ngIf="loading && lang.displayKey === currentLanguage"
                class="ms-1" color="primary" mode="indeterminate" diameter="20">
            </mat-progress-spinner>
      <mat-icon *ngIf="!loading && lang.displayKey === currentLanguage">check</mat-icon>
      {{ lang.displayKey }}
    </button>
</mat-menu>
       
<a mat-icon-button [matMenuTriggerFor]="languageMenu" class="icon-link btn-icon" [matTooltip]="'settings.language' | translate">
    <mat-icon>language</mat-icon>
</a>
  