import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from "../services/user.service";
import { HelperFileTypesEnum } from "../models/Util";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { UserDataStoreService } from "../shared/service/user-data-store.service";
import { ActivatedRoute, Router } from "@angular/router";
import {environment} from "../../environments/environment";
import {Location} from '@angular/common';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css'],
    animations: [
        trigger('slideUpDown', [
            state('visible', style({ transform: 'translateY(0%)' })),
            state('hidden', style({ transform: 'translateY(630%)' })),
            transition('visible => hidden', animate('300ms ease-out')),
            transition('hidden => visible', animate('300ms ease-in')),
        ]),
    ],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent {
    protected readonly environment = environment;

    protected readonly HelperFileTypesEnum = HelperFileTypesEnum;

    showSettingsIcons: boolean = true

    constructor(
        public userService: UserService,
        protected userDataStoreService: UserDataStoreService,
        protected router: Router,
        private route: ActivatedRoute,
        public location: Location
    ) {
        this.userService.simulationState.subscribe((state) => {
            if(state != null)
                this.showSettingsIcons = !state
        })

    }

    shouldShowCustomerPicker() {
        let isAdminOrSuperAdmin = this.userDataStoreService.isAdmin()
            || this.userDataStoreService.isSuperAdmin();
        return isAdminOrSuperAdmin && environment.loadDataFromBE &&
            this.location.path().includes('home')
            && this.showSettingsIcons;
    }
}
