export interface SettingFilterInput {
    page: SettingPageEnum;
    userId?: number;
    customerId?: number,
    settingKey?: SettingsKeys
}

export interface SettingValueList {
    userId?: number;
    customerId?: number;
    page: SettingPageEnum;
    settingValueList: SettingValue[];
}

export interface SettingValue {
    settingKey: string;
    value: string;
}

export enum SettingPageEnum {
    SITE = 1,
    USER = 2,
    CUSTOMER = 3
}

export enum SettingsKeys {
    DARK_MODE = 'darkMode',
    IMMEDIATE_FEEDBACK = 'immediateFeedback',
    SIMULATION_EMAIL_COUNT = 'simulationEmailCount',
    LANGUAGE = 'language'
}


export interface SettingGroup {
    settingDTOList: SettingItem[];
    groupName: string;
}


export interface SettingItem {
    settingID: string;
    settingName: string;
    settingKey: string;
    description: string;
    showInUI: boolean;
    settingOrder: number;
    typeName: string;
    defaultValue: string;
    displayValues: Array<SettingItemDisplayValue>;
    value: string;
    canView: boolean;
    canEdit: boolean;
    minValue: number;
    maxValue: number;
}

export interface SettingItemDisplayValue {
    displayKey: string;
    value: string;
    order: number;
}

export enum SettingType {
    LIST = 'List',
    TOGGLE = 'Toggle',
    INTEGER = 'Integer',
    TIME = 'Time',
    TEXT = 'Text'
}
