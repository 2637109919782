<mat-card class="mat-elevation-z4 wrapper-card h-100">
    <div style="height: 100%">
        <div class="emailListHeader">
            <h3>{{ 'side_nav.inbox' | translate }}</h3>
            <div>
                <mat-icon style="margin-right: 5px">content_copy</mat-icon>
                <mat-icon>filter_list</mat-icon>
            </div>
        </div>

        <div class="main-panel" [infiniteScrollContainer]="scrollSelector" infiniteScroll  [infiniteScrollDistance]="1"
             [infiniteScrollThrottle]="2" [fromRoot]="true" [infiniteScrollDisabled]="isLoading || disableScroll">
            <mat-selection-list (ngModelChange)="selectEmail($event)" [(ngModel)]="emailSelected"
                                [disabled]="disableSelection"
                                class="emailList" hideSingleSelectionIndicator="true"
                                multiple="false">
                <mat-list-option *ngFor="let email of emails; let id = index" [selected]="email.isSelected" [value]="id"
                                 class="emailItem p-2">
                    <div class="emailItemInnerDiv">
                        <div *ngIf="email.user_judgment_is_legit == undefined"
                             [ngStyle]="{'background-color': colors[id]}"
                             class="col-2 avatar"> {{ getInitials(email.senderDisplayName) }}
                        </div>
                        <img *ngIf="rightAnswer(email.legit, email.user_judgment_is_legit)"
                             class="me-2 fade-in col-2" src="assets/icons/{{userService.getThemeColor()}}/correct_answer.png" style="width: 45px; height: 45px">
                        <img *ngIf="wrongAnswer(email.legit, email.user_judgment_is_legit)"
                             class="me-2 fade-in col-2" src="assets/icons/{{userService.getThemeColor()}}/wrong_answer.png" style="width: 45px; height: 45px">
                        <div class="col-7 ellipsis">
                            <span class="emailItemSender"
                            matTooltip = "{{email.senderDisplayName}}"><strong>{{ email.senderDisplayName }}</strong></span>
                            <br>
                            <span class="emailItemSubject"
                            matTooltip = "{{email.subject}}">{{ email.subject }}</span>
                        </div>
                        <img *ngIf="email.isAnsweredPhishy == true"
                             class="mx-2 my-1 fade-in col-2" src="assets/icons/{{userService.getThemeColor()}}/phishy_icon.png" style="width: 12px; height: 18px">
                        <img *ngIf="email.isAnsweredPhishy == false"
                             class="mx-2 my-1 fade-in col-2" src="assets/icons/{{userService.getThemeColor()}}/legit_icon.png" style="width: 21px; height: 16px">
                    </div>
                </mat-list-option>
                <mat-list-option *ngIf="isLoading">
                    <app-loading-card></app-loading-card>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
</mat-card>
