import { SettingService } from '../../service/setting.service';
import { Subject } from 'rxjs';
import { HttpServiceFacade } from '../../http.service.facade';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
  SettingGroup,
  SettingItem,
  SettingPageEnum,
  SettingValue,
  SettingValueList,
} from '../../model/setting.model';
import { BaseForm } from '../../base-form';
import { UserDataStoreService } from '../../service/user-data-store.service';
import { AlertPopupService } from 'src/app/services/alert.popup.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-setting-group',
  templateUrl: './setting-group.component.html',
  styleUrls: ['./setting-group.component.scss']
})
export class SettingGroupComponent extends BaseForm implements OnInit, OnDestroy {
  @Input() settingGroup!: SettingGroup;
  @Input() page!: SettingPageEnum;
  @Input() showActionBtns = true;
  @Input() customerId!: number;
  private ngUnsubscribe: Subject<void> = new Subject();
  initialValues!: any;
  updatingSettings = false;

  constructor(
    protected httpServiceFacade: HttpServiceFacade,
    private settingService: SettingService,
    public userDataStoreService: UserDataStoreService,
    protected override alertPopupService: AlertPopupService,
    private translate: TranslateService
  ) {
    super(httpServiceFacade, alertPopupService);
  }

  override ngOnInit(): void {
    this.validationRules = [];
    this.settingGroup.settingDTOList.forEach(settingItem => {
      this.validationRules.push({
        key: 'required',
        field: settingItem.settingKey,
        message: this.translate.instant('validation.required', { field: settingItem.settingKey })
      });
      
      this.validationRules.push({
        key: 'isNumber',
        field: settingItem.settingKey,
        message: this.translate.instant('validation.number_must', { field: settingItem.settingKey })
      });
      
      this.validationRules.push({
        key: 'min',
        field: settingItem.settingKey,
        message: this.translate.instant('validation.min_value', { value: settingItem.minValue })
      });
      
      this.validationRules.push({
        key: 'max',
        field: settingItem.settingKey,
        message: this.translate.instant('validation.max_value', { value: settingItem.maxValue })
      });
      
      this.validationRules.push({
        key: 'minLength',
        field: settingItem.settingKey,
        message: this.translate.instant('validation.min_length', { value: settingItem.minValue })
      });
      
      this.validationRules.push({
        key: 'maxLength',
        field: settingItem.settingKey,
        message: this.translate.instant('validation.max_length', { value: settingItem.maxValue })
      });      
    });


    // sort the setting items by order
    this.settingGroup.settingDTOList.sort((a, b) => a.settingOrder - b.settingOrder);

    this.controlGroup = this.settingService.toFormGroup(this.settingGroup);
    // keep copy for discard btn
    this.initialValues = this.controlGroup.value;
  }

  showActionButtons() {
    const editableItems = this.settingGroup.settingDTOList.filter(setting => setting.canEdit);
    return this.showActionBtns && this.controlGroup?.dirty && editableItems.length > 0;

  }

  checkCanEditSettingItem(settingKey: string) {
    const settingItem = this.settingGroup.settingDTOList.find(setting => setting.settingKey == settingKey);

    if (!settingItem) {
      return false;
    }

    const valueToSave = this.controlGroup.controls[settingKey].value;
    return settingItem.canEdit && valueToSave != settingItem?.value;
  }

  onDiscardClick() {
    this.controlGroup.setValue(this.initialValues);
    this.controlGroup.markAsPristine();
  }

  showSetting(setting: SettingItem) {
    return setting.showInUI && setting.canView
  }

  saveSettings(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.updatingSettings = true;
      const input: SettingValueList = this.getSettingInput();

      this.settingService.saveSettings(input).toPromise()
        .then((data: any) => {
          this.initialValues = this.controlGroup.value;
          this.settingGroup.settingDTOList.forEach(setting => setting.value = this.initialValues[setting.settingKey]);
          this.alertPopupService.success(this.translate.instant('dialog.setting_saved'));
          this.controlGroup.markAsPristine();
          this.updatingSettings = false;
          resolve();
        })
        .catch(err => {
          this.alertPopupService.error(this.translate.instant('dialog.setting_save_failed'));
          this.updatingSettings = false;
          reject(err);
        });
    });
  }




  getSettingType(key: string): string {
    const setting = this.settingGroup.settingDTOList.find(e => e.settingKey == key);
    if (setting) {
      return setting.typeName;
    }
    return '';
  }

  getSettingInput(saveImage: boolean = true) {
    const settingList: SettingValue[] = [];
    Object.keys(this.controlGroup.controls).forEach(key => {
      if (!this.checkCanEditSettingItem(key)) {
        return;
      }
      const valueToSave = this.controlGroup.controls[key].value;
      settingList.push({ settingKey: key, value: valueToSave });
    });
    const input: SettingValueList = {
      page: this.page,
      userId: this.userDataStoreService.getUser().userID!,
      settingValueList: settingList,
      customerId: this.customerId
    };
    return input;
  }

  override ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
