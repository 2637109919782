<div class="container d-flex justify-content-center" style="height: 80%; align-items: center">
    <div class="text-center">
        <img alt="" src="assets/icons/customer_information.png" style="width: 210px; height: 250px;">
        <div class="">
            <h1>
                404
            </h1>
            <h1>
                {{'common.resource_not_found' | translate}}
            </h1>
        </div>
    </div>
</div>
