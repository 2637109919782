import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {EmailDTO, EmailPhishingAttributesDTO} from "../models/PhishingAttributesModel";
import {MatDialog} from "@angular/material/dialog";
import {ReusableDialogComponent} from "../reusable-dialog/reusable-dialog.component";
import {ScoreModel} from "../models/ScoreModel";
import {PhishingAttributesEnums} from "../models/PhishingAttributesEnums";
import {DialogEnums} from "../models/DialogEnums";
import {EmailService} from "../shared/service/email.service";
import { AlertPopupService } from '../services/alert.popup.service';
import {ExtraHelpDialogsService} from "../services/extra-help-dialogs-service";
import {HelperFileTypesEnum} from "../models/Util";
import {UserService} from "../services/user.service";
import {UserDataStoreService} from "../shared/service/user-data-store.service";
import {ActivatedRoute, Router} from "@angular/router";


enum QuestionsInMailStatusEnum {
    NotAnswered,
    AnsweredPhishy,
    AnsweredLegit
}

@Component({
    selector: 'app-scoring-panel',
    templateUrl: './scoring-panel.component.html',
    styleUrls: ['./scoring-panel.component.css']
})
export class ScoringPanelComponent implements OnInit, OnChanges {
    @Input() email!: EmailDTO;
    @Input() immediateFeedback!: boolean;
    @Input() lastEmail!: boolean;
    @Input() continueToNext!: (emailId: number) => void;
    @Output() submitEvent: EventEmitter<ScoreModel> = new EventEmitter<ScoreModel>();
    @Output() continueEvent: EventEmitter<number> = new EventEmitter<number>();

    protected readonly HelperFileTypesEnum = HelperFileTypesEnum;
    protected readonly questionsInMailStatusEnum = QuestionsInMailStatusEnum;

    senderLegitimate: any = undefined;
    contentLegitimate: any = undefined;
    linksLegitimate: any = undefined;
    attachmentsLegitimate: any = undefined;
    qrCodeLegitimate: any = undefined;
    dialogData = DialogEnums;

    senderDesc = "Investigate who actually sent the email not just the name that is displayed."
    contentDesc = "Examine the subject and body of the email for anything out of the ordinary."
    linksDesc = "Check links before clicking to make sure their destination is what you expect and not somewhere malicious."
    attachmentDesc = "Before opening attachments, consider if you were expecting them and ensure the file type is appropriate. Be cautious of executable files and potential macros."
    qrCodeDesc = "Check links before clicking to make sure their destination is what you expect and not somewhere malicious."
    attachmentNotRatable = "The attachments are not ratable here."

    protected readonly PhishingAttributesEnums = PhishingAttributesEnums;

    constructor(
        private dialog: MatDialog,
        protected emailService: EmailService,
        private alertPopupService: AlertPopupService,
        public extraHelpDialogsService: ExtraHelpDialogsService,
        public userService: UserService,
        protected userDataStoreService: UserDataStoreService,
        protected router: Router,
        private route: ActivatedRoute
    ) {
    }

    questionsInMail: Map<PhishingAttributesEnums, boolean | undefined> = new Map<PhishingAttributesEnums, undefined>();


    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["email"]?.currentValue) {
            this.email = changes["email"].currentValue
            this.questionsInMail = new Map<PhishingAttributesEnums, boolean>();

            this.setAnswers()
        }
        console.log(this.lastEmail);
    }

    setAnswers() {
        if (this.email.scores) {
            this.senderLegitimate = this.email.scores.get(PhishingAttributesEnums.SENDER)
            this.contentLegitimate = this.email.scores.get(PhishingAttributesEnums.CONTENT)
            this.linksLegitimate = this.email.scores.get(PhishingAttributesEnums.LINKS)
            this.qrCodeLegitimate = this.email.scores.get(PhishingAttributesEnums.QR_CODE)
            this.attachmentsLegitimate = this.email.scores.get(PhishingAttributesEnums.ATTACHMENTS)
        } else {
            this.senderLegitimate = undefined;
            this.contentLegitimate = undefined;
            this.linksLegitimate = undefined;
            this.qrCodeLegitimate = undefined;
            this.attachmentsLegitimate = undefined;

            this.questionsInMail = new Map<PhishingAttributesEnums, boolean>();
        }

        if (this.email.emailPhishingAttributes?.isSenderPhishy != undefined)
            this.questionsInMail.set(PhishingAttributesEnums.SENDER, this.senderLegitimate)
        if (this.email.emailPhishingAttributes?.isContentPhishy != undefined)
            this.questionsInMail.set(PhishingAttributesEnums.CONTENT, this.contentLegitimate)
        if (this.email.emailPhishingAttributes?.isLinkPhishy != undefined)
            this.questionsInMail.set(PhishingAttributesEnums.LINKS, this.linksLegitimate)
        if (this.email.emailPhishingAttributes?.isAttachmentPhishy != undefined && this.email.emailPhishingAttributes?.attachments)
            this.questionsInMail.set(PhishingAttributesEnums.ATTACHMENTS, this.attachmentsLegitimate)
        if (this.email.emailPhishingAttributes?.isQRCodePhishy != undefined && this.email.emailPhishingAttributes?.qRCodeURL)
            this.questionsInMail.set(PhishingAttributesEnums.QR_CODE, this.qrCodeLegitimate)
    }


    getBackgroundColor(emailAttribute: boolean | undefined, answer: boolean | undefined) {
        if (emailAttribute != undefined)
            return answer == undefined ? 'blue-con' : (answer ? 'green-bg' : 'red-bg');
        return 'disable'
    }

    legitimateBtnColor(legitimate = false) {
        return legitimate ? 'green-bg white-color' : 'white-bg black-color';
    }

    phishyBtnColor(legitimate: any = true) {
        return !legitimate ? 'red-bg white-color' : 'white-bg black-color';
    }

    markAnswered(question: PhishingAttributesEnums, answer: boolean) {
        this.questionsInMail.set(question, answer)
    }

    onSenderClick(answer: boolean) {
        this.senderLegitimate = answer

        this.markAnswered(PhishingAttributesEnums.SENDER, this.senderLegitimate)
    }

    onContentClick(answer: boolean) {
        this.contentLegitimate = answer

        this.markAnswered(PhishingAttributesEnums.CONTENT, this.contentLegitimate)
    }

    onLinksClick(answer: boolean) {
        this.linksLegitimate = answer

        this.markAnswered(PhishingAttributesEnums.LINKS, this.linksLegitimate)
    }

    onAttachmentsClick(answer: boolean) {
        this.attachmentsLegitimate = answer

        this.markAnswered(PhishingAttributesEnums.ATTACHMENTS, this.attachmentsLegitimate)
    }

    onQrCodeClick(answer: boolean) {
        this.qrCodeLegitimate = answer

        this.markAnswered(PhishingAttributesEnums.QR_CODE, this.qrCodeLegitimate)
    }

    onContinueClick(divContainer: any){
        divContainer.scrollTo({top: 0, behavior: 'smooth'});
        this.continueEvent.emit(this.email.id);
    }

    onSubmitClicked(divContainer: any) {
        let scores: Map<PhishingAttributesEnums, boolean> = new Map<PhishingAttributesEnums, boolean>();
        if (this.email?.emailPhishingAttributes?.isSenderPhishy != undefined && this.senderLegitimate != undefined)
            scores.set(PhishingAttributesEnums.SENDER, this.senderLegitimate)

        if (this.email?.emailPhishingAttributes?.isContentPhishy != undefined && this.contentLegitimate != undefined)
            scores.set(PhishingAttributesEnums.CONTENT, this.contentLegitimate)

        if (this.email?.emailPhishingAttributes?.isLinkPhishy != undefined && this.linksLegitimate != undefined)
            scores.set(PhishingAttributesEnums.LINKS, this.linksLegitimate)

        if (this.email?.emailPhishingAttributes?.isAttachmentPhishy != undefined && this.attachmentsLegitimate != undefined)
            scores.set(PhishingAttributesEnums.ATTACHMENTS, this.attachmentsLegitimate)

        if (this.email?.emailPhishingAttributes?.isQRCodePhishy != undefined && this.qrCodeLegitimate != undefined)
            scores.set(PhishingAttributesEnums.QR_CODE, this.qrCodeLegitimate)

        let scoreModel: ScoreModel = {
            id: this.email.id,
            scores: scores
        };

        this.email.isAnsweredPhishy = this.getIsAnswerPhishy(scores);

        this.submitEvent.emit(scoreModel);

        this.alertPopupService.success("Selections are submitted successfully", undefined,  {'positionClass': 'toast-bottom-left'})
        if(!this.immediateFeedback)
            this.onContinueClick(divContainer)
    }
    rightAnswer(answer: boolean | undefined, userAnswer: boolean | undefined): boolean {
        return userAnswer != undefined && answer === userAnswer;
    }

    wrongAnswer(answer: boolean | undefined, userAnswer: boolean | undefined): boolean {
        return userAnswer != undefined && answer != userAnswer;
    }
    getQuestionsInMailStatus(): QuestionsInMailStatusEnum {
        let notAnswered = false;
        let phishy = false;
        this.questionsInMail.forEach((value, key) => {
            if (value == undefined)
                notAnswered = true;
            else if (!value)
                phishy = true;
        })

        return notAnswered ? QuestionsInMailStatusEnum.NotAnswered :
            (phishy? QuestionsInMailStatusEnum.AnsweredPhishy : QuestionsInMailStatusEnum.AnsweredLegit);
    }

    onHintClicked(data: string, title: string) {
        const dialogRef = this.dialog.open(ReusableDialogComponent, {
            width: '500px', data: {
                title: title,
                data: data
            }
        });
    }

    startOver() {
        this.userService.logout()
    }

    navigateToCustomer() {
        this.userService.handleSimulation(false);
        if (this.userDataStoreService.isAdmin() || this.userDataStoreService.isSuperAdmin())
            this.router.navigate([`/admin`], { relativeTo: this.route })
        else {
            let customer = this.userDataStoreService.getUser().customerDTO
            this.router.navigate([`/customers/`, customer!.id, customer!.name], { relativeTo: this.route })
        }
    }

    private getIsAnswerPhishy(scores: Map<PhishingAttributesEnums, boolean>) {
        let isAnswerPhishy = false;
        scores.forEach((value, key) => {
            if (!value)
                isAnswerPhishy =  true;
        });

        return isAnswerPhishy;
    }
}
