import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {RouterModule} from '@angular/router';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {ToastrModule} from 'ngx-toastr';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpServiceFacade} from "./http.service.facade";
import {AlertPopupService} from "../services/alert.popup.service";
import {ValidatorsService} from "./service/validator.service";
import {CountryStateCityComponent} from './component/country-state-city/country-state-city.component';
import {CountryService} from "./service/country.service";
import {LoadingTableComponent} from "./component/loadings/loading-table/loading-table.component";
import {LoadingCardComponent} from "./component/loadings/loading-card-list/loading-card/loading-card.component";
import {LoadingCardListComponent} from "./component/loadings/loading-card-list/loading-card-list.component";
import { SanitizeHtmlPipe } from '../pipes/SanitizeHTML.pipe';
import { SettingGroupComponent } from './component/setting-group/setting-group.component';
import { SettingItemComponent } from './component/setting-item/setting-item.component';
import { IncrementInputComponent } from './component/setting-items/increment-input/increment-input.component';
import { SettingPageComponent } from './component/setting-page/setting-page.component';
import { GenericConfirmationDialog } from './component/generic-confirmation/generic-confirmation.dialog';
import { HtmlEditorComponent } from './component/html-editor/html-editor.component';
import {NotFoundComponent} from "./component/not-found/not-found.component";
import {TranslateModule} from '@ngx-translate/core';

import {CustomerPickerComponent} from "./component/customer-picker/customer-picker.component";
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';



@NgModule({
    imports: [
        RouterModule,
        TypeaheadModule.forRoot(),
        A11yModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        MatFormFieldModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        RouterModule,
        NgbPopoverModule,
        ToastrModule.forRoot({
            preventDuplicates: true
        }),
        NuMonacoEditorModule.forRoot({
        }),
        TranslateModule
    ],
    declarations: [
        CountryStateCityComponent,
        LoadingTableComponent,
        SanitizeHtmlPipe,
        LoadingCardComponent,
        LoadingCardListComponent,
        SettingPageComponent,
        SettingGroupComponent,
        IncrementInputComponent,
        SettingItemComponent,
        GenericConfirmationDialog,
        HtmlEditorComponent,
        NotFoundComponent,
        CustomerPickerComponent
    ],
    exports: [
        RouterModule,
        TypeaheadModule,
        A11yModule,
        MatFormFieldModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CountryStateCityComponent,
        LoadingTableComponent,
        SanitizeHtmlPipe,
        LoadingCardComponent,
        LoadingCardListComponent,
        SettingPageComponent,
        SettingGroupComponent,
        IncrementInputComponent,
        SettingItemComponent,
        GenericConfirmationDialog,
        HtmlEditorComponent,
        NotFoundComponent,
        CustomerPickerComponent,
        TranslateModule
    ],
    providers: [CountryService]
})
export class SharedModule {
    static forRoot() {
        return {
            ngModule: SharedModule,
            providers: [
                MatDatepickerModule,
                HttpServiceFacade,
                AlertPopupService,
                ValidatorsService,
            ]
        };
    }
}
