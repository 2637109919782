<mat-toolbar class="p-0 px-1 toolbar justify-content-between" style="height: 48px">
    <div class="d-flex align-items-center">
        <a routerLink="/home" style="text-decoration: none;">
            <span class="ms-3" *ngIf="location.path().includes('home')">
                {{ userDataStoreService.getUserEmailClient() }}
            </span>
            <span class="d-flex ms-1" *ngIf="!location.path().includes('home')">
                <mat-icon class="me-1">arrow_back</mat-icon>
                <span style="font-size: large;">{{'welcome.return_to_simulation' | translate}}</span>
            </span>
        </a>
    </div>

    <div *ngIf="router.url == '/home'"
         class="d-flex mx-5">
    </div>

    <div class="d-flex" *ngIf="shouldShowCustomerPicker()">
        <app-customer-picker></app-customer-picker>
    </div>

    <div *ngIf="showSettingsIcons">
        <app-settings-icons></app-settings-icons>
    </div>

</mat-toolbar>
