<form [formGroup]="controlGroup">
    <div *ngFor="let setting of settingGroup.settingDTOList">
        <app-setting-item *ngIf="showSetting(setting)" [setting]="setting" [form]="controlGroup"
                          [errors]="fieldErrors(setting.settingKey)"></app-setting-item>
    </div>
    <!-- Action Buttons -->
    <div *ngIf="showActionButtons()" class="d-flex justify-content-end align-items-end p-1">
        <button type="button" mat-button class="m-1" (click)="onDiscardClick()"
            [disabled]="!controlGroup.dirty || updatingSettings">
            <span class="d-flex justify-content-center align-items-center">
                <span>{{ 'dialog.discard' | translate }}</span>
            </span>
        </button>
        <button mat-raised-button class="m-1" (click)="saveSettings()" color="primary"
            [disabled]="!controlGroup.dirty || controlGroup.invalid || updatingSettings">
            <span class="d-flex justify-content-center align-items-center">
                <span>{{ 'common.save' | translate }}</span>
                <mat-progress-spinner class="ms-1" diameter="20" color="primary" mode="indeterminate"
                    *ngIf="updatingSettings">
                </mat-progress-spinner>
            </span>
        </button>
    </div>
</form>
