<div [formGroup]="form">

  <div [ngSwitch]="setting.typeName" class="m-2">

    <app-increment-input *ngSwitchCase="SettingType.INTEGER" [setting]="setting" [form]="form" class="small-mat-form"
      [errors]="errors" [disabled]="!setting.canEdit || disabled"></app-increment-input>

    <div *ngSwitchCase="SettingType.TOGGLE" class="d-flex justify-content-between align-items-center mt-3 mb-3 small-mat-form">
        <mat-label style="flex: 1;">
          {{ ('settings.' + setting.settingName.toLowerCase().split(' ').join('_')) | translate }}
        </mat-label>
      <mat-button-toggle-group style="flex: 0.75; max-width: 400px" [id]="setting.settingKey" [formControlName]="setting.settingKey">
        <mat-button-toggle class="w-50" [disabled]="!setting.canEdit || disabled" *ngFor="let opt of setting.displayValues"
          [value]="opt.value">{{ ('settings.' + opt.displayKey.toLowerCase().split(' ').join('_')) | translate }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div *ngSwitchCase="SettingType.TEXT" class="d-flex justify-content-between align-items-center small-mat-form">
      <mat-label style="flex: 1;">{{ ('settings.' + setting.settingName.toLowerCase().split(' ').join('_')) | translate }}</mat-label>
      <mat-form-field style="flex: 0.75; max-width: 400px" appearance="outline">
        <input matInput [disabled]="!setting.canEdit || disabled" [id]="setting.settingKey"
          [formControlName]="setting.settingKey" class="small-mat-form"/>
        <mat-error style="padding-top: 3px;">{{errors}}</mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="SettingType.LIST" class="d-flex justify-content-between align-items-center small-mat-form">
      <mat-label style="flex: 1;">{{ ('settings.' + setting.settingName.toLowerCase().split(' ').join('_')) | translate }}</mat-label>
      <mat-form-field style="flex: 0.75; max-width: 400px" appearance="outline">
        <mat-select [disabled]="!setting.canEdit || disabled" [id]="setting.settingKey"
          [formControlName]="setting.settingKey" class="small-mat-form">
          <mat-option *ngFor="let opt of setting.displayValues" class="small-mat-form" [value]="opt.value">{{opt.displayKey}}</mat-option>
        </mat-select>
        <mat-error *ngIf="!isValid" class="mt-1">
          {{ 'validation.required' | translate: { field: setting.settingName } }}
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="SettingType.TIME" class="d-flex justify-content-between align-items-center small-mat-form">
      <mat-label style="flex: 1;">{{ ('settings.' + setting.settingName.toLowerCase().split(' ').join('_')) | translate }}</mat-label>
      <mat-form-field style="flex: 0.75; max-width: 400px" appearance="outline">
        <input matInput type="time" [disabled]="!setting.canEdit || disabled" [id]="setting.settingKey"
          [formControlName]="setting.settingKey" class="small-mat-form"/>
        <mat-error style="padding-top: 3px;">{{errors}}</mat-error>
      </mat-form-field>
    </div>
  </div>

</div>
