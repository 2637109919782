<div class="text-center p-4 br-5" style=" height: 91%; overflow-y: auto;  background-color: var(--container-background)">
  <h1>{{'welcome.welcome' | translate}}</h1>
  <p class="welcome mt-3">{{'welcome.let_us_help' | translate}}</p>

  <p class="welcome mt-2">{{'welcome.going_through_welcome' | translate}}</p>
  <p class="welcome">{{'welcome.after_completing_emails' | translate}}</p>
  <p class="welcome">{{'welcome.let_us_begin' | translate}}</p>

  <button (click)="startSimulationClicked()" class="mt-3 theme-button" mat-raised-button [disabled]="isLoadingEmails">{{'welcome.start_simulation' | translate}}
  </button>

    <div class="d-flex align-items-center justify-content-center my-4">
        <span> <strong>{{'common.hints' | translate}}: </strong></span>
        <div class="d-flex mx-2">
            <div class="icon-with-text" (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.SENDER)">
                <mat-icon class="hintIcon">
                    contact_mail
                </mat-icon>
                <span class="icon-text">{{'common.sender' | translate}}</span>
            </div>
            <div class="icon-with-text" (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.CONTENT)">
                <mat-icon class="hintIcon">
                    chat
                </mat-icon>
                <span class="icon-text">{{'common.content' | translate}}</span>
            </div>
            <div class="icon-with-text" (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.LINKS)">
                <mat-icon class="hintIcon">
                    language
                </mat-icon>
                <span class="icon-text">{{'welcome.links' | translate}}</span>
            </div>
            <div class="icon-with-text" (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.ATTACHMENTS)">
                <mat-icon class="hintIcon">
                    attach_file
                </mat-icon>
                <span class="icon-text">{{'common.attachments' | translate}}</span>
            </div>
        </div>
    </div>
</div>
