<div #divContainer class="h-100" style="overflow-x: hidden; overflow-y: auto">
    <mat-card class="background">
        <div class="d-flex justify-content-end my-2">
            <div class="d-flex justify-content-between me-3" style="width: 120px">
                <app-theme-toggle></app-theme-toggle>

                <a (click)="navigateToCustomer()" [class.disabled]="userDataStoreService.isEXEMode()" style="text-decoration: none">
                    <mat-icon class="btn-icon">settings_outline</mat-icon>
                </a>

                <mat-icon *ngIf="userService.getAllowLogout()" (click)="startOver()" class="btn-icon">logout</mat-icon>
            </div>
        </div>

        <div class="sm-blue-con row d-flex"></div>

        <div class="d-flex justify-content-center">
            <span style="font-size: 44px; font-weight: 500">Scoring Panel</span>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <span> <strong>Hints: </strong></span>
            <div class="d-flex mx-2">
                <mat-icon (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.SENDER)" class="mx-2"
                          style="cursor: pointer">
                    contact_mail
                </mat-icon>
                <mat-icon (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.LINKS)" class="mx-2"
                          style="cursor: pointer">
                    language
                </mat-icon>
                <mat-icon (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.CONTENT)" class="mx-2"
                          style="cursor: pointer">
                    chat
                </mat-icon>
                <mat-icon (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.ATTACHMENTS)" class="mx-2"
                          style="cursor: pointer">
                    attach_file
                </mat-icon>
            </div>
        </div>

        <span style="font-size: 16px" class="p-1">
            <strong class="underline">Scenario</strong> <br>
            <span class="ms-2">{{email?.description}}</span>
        </span>
        <div class="px-0 container column">
            <div [ngClass]="getBackgroundColor(email?.emailPhishingAttributes?.isSenderPhishy, senderLegitimate)" class="d-flex space-between white-color">
                <span [ngStyle]="{'visibility': (email?.scores && email?.emailPhishingAttributes?.isSenderPhishy != undefined) ? 'visible' : 'hidden'}"
                      style="flex: 1; display: flex; justify-content: center" >
                    <img style="width: 36px; height: 36px" [src]="emailService.isWrongAnswer(email, PhishingAttributesEnums.SENDER)">
                </span>
                <span style="flex: 3" class=" s-23 ps-2">Sender</span>
                <div style="flex: 2" class="d-flex justify-content-end align-items-center pe-2">
                    <span *ngIf="senderLegitimate != undefined" class="pe-2 s-14">
                        {{ senderLegitimate ? 'Legitimate' : 'Phishing' }}
                    </span>
                    <div (click)="onHintClicked(dialogData.SENDER_DIALOG, 'Sender')" class="circle">?</div>
                </div>
            </div>
            <ng-container *ngIf="email?.emailPhishingAttributes?.isSenderPhishy  !=undefined">
                <span class="d-block p-2 s-14">{{senderDesc}}</span>
                <div class=" flex-center px-2">
                    <button (click)="onSenderClick(true)" [ngClass]="legitimateBtnColor(senderLegitimate)"
                            class="op-lf-btn" [disabled]="email?.scores">
                        Seems Legit
                    </button>
                    <button (click)="onSenderClick(false)" [ngClass]="phishyBtnColor(senderLegitimate)"
                            class="op-rt-btn" [disabled]="email?.scores">
                        Feels Phishy
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="px-0 container column">
            <div [ngClass]="getBackgroundColor(email?.emailPhishingAttributes?.isContentPhishy, contentLegitimate)" class="d-flex space-between white-color">
                <span [ngStyle]="{'visibility': (email?.scores && email?.emailPhishingAttributes?.isContentPhishy != undefined) ? 'visible' : 'hidden'}"
                      style="flex: 1; display: flex; justify-content: center" >
                    <img style="width: 36px; height: 36px" [src]="emailService.isWrongAnswer(email, PhishingAttributesEnums.CONTENT)">
                </span>
                <span style="flex: 3" class=" s-23 ps-2">Content</span>
                <div style="flex: 2" class="d-flex justify-content-end align-items-center pe-2">
                    <span *ngIf="contentLegitimate != undefined" class="pe-2 s-14">
                        {{ contentLegitimate ? 'Legitimate' : 'Phishing' }}
                    </span>
                    <button (click)="onHintClicked(dialogData.CONTENT_DIALOG, 'Content')" class="circle">?</button>
                </div>
            </div>
            <ng-container *ngIf="email?.emailPhishingAttributes?.isContentPhishy  !=undefined">
                <span
                    class="d-block p-2 s-14">{{contentDesc}}</span>
                <div class=" flex-center px-2">
                    <button (click)="onContentClick(true)" [ngClass]="legitimateBtnColor(contentLegitimate)"
                            class="op-lf-btn" [disabled]="email?.scores">
                        Seems Legit
                    </button>
                    <button (click)="onContentClick(false)" [ngClass]="phishyBtnColor(contentLegitimate)"
                            class="op-rt-btn" [disabled]="email?.scores">
                        Feels Phishy
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="px-0 container column">
            <div [ngClass]="getBackgroundColor(email?.emailPhishingAttributes?.isLinkPhishy, linksLegitimate)" class="d-flex space-between white-color">
                <span *ngIf="email?.emailPhishingAttributes?.isLinkPhishy != undefined" [ngStyle]="{'visibility': (email?.scores && email?.emailPhishingAttributes?.isLinkPhishy != undefined) ? 'visible' : 'hidden'}"
                      style="flex: 1; display: flex; justify-content: center" >
                    <img style="width: 36px; height: 36px" [src]="emailService.isWrongAnswer(email, PhishingAttributesEnums.LINKS)">
                </span>
                <span style="flex: 3" class=" s-23 ps-2">{{email?.emailPhishingAttributes?.isLinkPhishy == undefined ? 'No ' : ''}}Links</span>
                <div style="flex: 2" class="d-flex justify-content-end align-items-center pe-2">
                    <span *ngIf="linksLegitimate != undefined" class="pe-2 s-14">
                        {{ linksLegitimate ? 'Legitimate' : 'Phishing' }}
                    </span>
                    <div (click)="onHintClicked(dialogData.LINK_DIALOG, 'Links')" class="circle">?</div>
                </div>
            </div>
            <ng-container *ngIf="email?.emailPhishingAttributes?.isLinkPhishy  !=undefined">
                <span class="d-block p-2 s-14">{{linksDesc}}</span>
                <div  class=" flex-center px-2">
                    <button (click)="onLinksClick(true)" [ngClass]="legitimateBtnColor(linksLegitimate)"
                            class="op-lf-btn" [disabled]="email?.scores">
                        Seems Legit
                    </button>
                    <button (click)="onLinksClick(false)" [ngClass]="phishyBtnColor(linksLegitimate)"
                            class="op-rt-btn" [disabled]="email?.scores">
                        Feels Phishy
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="px-0 container column">
            <div [ngClass]="getBackgroundColor(!email?.emailPhishingAttributes?.attachments ? undefined : true, attachmentsLegitimate)" class="d-flex white-color">
                <span *ngIf="email?.emailPhishingAttributes?.attachments" [ngStyle]="{'visibility': (email?.scores && email?.emailPhishingAttributes?.isAttachmentPhishy != undefined) ? 'visible' : 'hidden'}"
                      style="flex: 1; display: flex; justify-content: center" >
                    <img style="width: 36px; height: 36px" [src]="emailService.isWrongAnswer(email, PhishingAttributesEnums.ATTACHMENTS)">
                </span>
                <span style="flex: 3" class="s-23 ps-2">{{!email?.emailPhishingAttributes?.attachments ? 'No ' : ''}}Attachments</span>
                <div style="flex: 2" class="d-flex justify-content-end align-items-center pe-2">
                    <span *ngIf="attachmentsLegitimate != undefined" class="pe-2 s-14">
                        {{ attachmentsLegitimate ? 'Legitimate' : 'Phishing' }}</span>
                    <div (click)="onHintClicked(dialogData.ATTACHMENTS_DIALOG, 'Attachments')" class="circle">?
                    </div>
                </div>
            </div>
            <ng-container *ngIf="email?.emailPhishingAttributes?.attachments && email?.emailPhishingAttributes?.isAttachmentPhishy !=undefined">
                <span class="d-block p-2 s-14">{{attachmentDesc}}</span>
                <div class=" flex-center px-2">
                    <button (click)="onAttachmentsClick(true)" [ngClass]="legitimateBtnColor(attachmentsLegitimate)"
                            class="op-lf-btn" [disabled]="email?.scores">
                        Seems Legit
                    </button>
                    <button (click)="onAttachmentsClick(false)" [ngClass]="phishyBtnColor(attachmentsLegitimate)"
                            class="op-rt-btn" [disabled]="email?.scores">
                        Feels Phishy
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="email?.emailPhishingAttributes?.attachments && email?.emailPhishingAttributes?.isAttachmentPhishy ==undefined">
                <span class="d-block p-2">
                    {{attachmentNotRatable}}
                </span>
            </ng-container>
        </div>

        <div class="container column">
            <div class="sm-blue-con row d-flex"></div>
            <div class="flex-center flex-column pt-2">
                <button *ngIf="email?.user_judgment_is_legit === undefined"
                    (click)="onSubmitClicked(divContainer)" class="submit-btn flex-center"
                        [disabled]="email?.scores || getQuestionsInMailStatus() == questionsInMailStatusEnum.NotAnswered"
                [ngClass]="{'dark-blue-con': getQuestionsInMailStatus() != questionsInMailStatusEnum.NotAnswered,
                 'disable': getQuestionsInMailStatus() == questionsInMailStatusEnum.NotAnswered}">
                    <span *ngIf="getQuestionsInMailStatus() ==  questionsInMailStatusEnum.NotAnswered" class="white-color">
                        Mark Email As
                    </span>
                    <span *ngIf="getQuestionsInMailStatus() == questionsInMailStatusEnum.AnsweredPhishy" class="white-color">
                        Mark Email As Phishy <img
                                                  class="ms-1 fade-in" src="assets/icons/mark_email_phishy.png">
                    </span>

                    <span *ngIf="getQuestionsInMailStatus() == questionsInMailStatusEnum.AnsweredLegit" class="white-color">
                        Mark Email As Legit <img
                        class="ms-1 fade-in" src="assets/icons/mark_email_legit.png">
                    </span>
                </button>
                <div *ngIf="rightAnswer(email?.legit, email?.user_judgment_is_legit)">
                    <span *ngIf="email?.isAnsweredPhishy">
                        <img
                            class="me-1 fade-in" src="assets/icons/check_mark.png">
                        Phishy
                        <img
                            class="mx-1 fade-in" src="assets/icons/{{userService.getThemeColor()}}/phishy_icon.png">
                        was the correct answer!</span>

                    <span *ngIf="!email?.isAnsweredPhishy">
                        <img
                            class="me-1 fade-in" src="assets/icons/check_mark.png">
                        Legit
                        <img
                        class="mx-1 fade-in" src="assets/icons/{{userService.getThemeColor()}}/legit_icon.png">

                        was the correct answer!</span>
                </div>
                <div *ngIf="wrongAnswer(email?.legit, email?.user_judgment_is_legit)">
                    <span *ngIf="email?.isAnsweredPhishy">

                        <img
                            class="me-1 fade-in" src="assets/icons/wrong_mark.png">

                        Phishy

                         <img
                             class="mx-1 fade-in" src="assets/icons/{{userService.getThemeColor()}}/phishy_icon.png">
                        was incorrect</span>

                    <span *ngIf="!email?.isAnsweredPhishy">

                        <img
                            class="me-1 fade-in" src="assets/icons/wrong_mark.png">
                        Legit

                        <img
                            class="mx-1 fade-in" src="assets/icons/{{userService.getThemeColor()}}/legit_icon.png">

                        was incorrect</span>
                </div>
                <span *ngIf="!lastEmail" (click)="onContinueClick(divContainer)" class="mt-2"
                    [ngClass]="{'link': email?.scores, 'disabled-link': !email?.scores}">
                    Proceed to Next Email
                </span>
                <span *ngIf="lastEmail" (click)="onContinueClick(divContainer)" class="mt-2"
                      [ngClass]="{'link': email?.scores, 'disabled-link': !email?.scores}">
                    Proceed to Scoring
                </span>
            </div>
        </div>
    </mat-card>
</div>
