<div class="d-flex justify-content-center br-5" style="background: var(--container-background)">
    <div class="main-card px-5 m-5">
        <h1 class="mb-5"><strong>{{ 'scoring_panel.simulation_complete' | translate }}</strong></h1>
        <div class="m-4 d-flex flex-column justify-content-center align-items-center">
            <h3>
                <strong>
                    {{ 'scoring_panel.your_score' | translate }}: 
                    ({{ emails.score }} {{ 'scoring_panel.of' | translate }} {{ emails.total }}) 
                    {{ emails.percentage }}
                </strong>
            </h3>
            <div>
                <button class="score-btn mt-5 mx-2 px-4" (click)="restart()">
                    {{ 'scoring_panel.restart' | translate }}
                </button>
                <button class="score-btn mt-5 mx-2 px-4" (click)="onSubmitScoreBtnClick()">
                    {{ 'scoring_panel.submit_score' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>