import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserDataStoreService } from '../shared/service/user-data-store.service';
import { SettingPageEnum, SettingsKeys, SettingFilterInput } from '../shared/model/setting.model';
import { AlertPopupService } from './alert.popup.service';
import { SettingService } from '../shared/service/setting.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizeService {
  private readonly defaultLang: string = 'en';

  constructor(
    public translate: TranslateService,
    private userDataStoreService: UserDataStoreService,
    private alertPopupService: AlertPopupService,
    private settingService: SettingService  
  ) {}

  private useLanguage(language: string, resolve: () => void, reject: (error: any) => void): void {
    this.translate.use(language).subscribe({
      next: () => resolve(),
      error: (error) => reject(error)
    });
  }

  initializeTranslation(): Promise<void> {
    return new Promise((resolve, reject) => {

      const user = this.userDataStoreService.getUser();

      if (!user || !user.userID) {
        this.useLanguage(this.defaultLang, resolve, reject);
        return;
      }
      const input: SettingFilterInput = {
        page: SettingPageEnum.USER,
        userId: user.userID,
        settingKey: SettingsKeys.LANGUAGE
      };

      this.settingService.getSettingValue(input)
      .pipe(
          catchError(err => {
            this.alertPopupService.error("Failed to retrieve user language");
            return of(this.defaultLang);
          })
        )
        .subscribe({
          next: (languageValue: string) => {
            this.useLanguage(languageValue, resolve, reject);
          },
          error: (err) => {
            this.alertPopupService.error('Failed to retrieve user language. Switching to default.');
            this.useLanguage(this.defaultLang, resolve, reject);
          }
        });
    });
  }
}